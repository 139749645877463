/* Customize the scrollbar track */
::-webkit-scrollbar {
    width: 3px; /* Set the width of the scrollbar */
  }
  
  /* Customize the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #4b5563; /* Set the color of the thumb */
    border-radius: 5px; /* Set the border radius of the thumb */
  }
  
  /* Customize the scrollbar track */
  ::-webkit-scrollbar-track {
    background-color: #cbd5e0; /* Set the color of the track */
  }
  
  /* Customize the scrollbar corner */
  ::-webkit-scrollbar-corner {
    background-color: transparent; /* Set the color of the scrollbar corner */
  }
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
     margin: 0; 
    font-family: "Public Sans", "Noto Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: <weight>;
    font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Add horizontal scrollbar styling */
/* Webkit (Chrome, Safari, newer versions of Opera) */
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #011354;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #011354;
}

.table-container {
  @apply min-w-full divide-y-2 divide-gray-200 bg-white text-sm;
}
.table-header {
  /* @apply bg-[#F0F1F3] text-[#1D1F2C] font-medium text-sm; */
}
.table-head {
  @apply whitespace-nowrap px-4 py-2 text-left font-medium cursor-pointer;
}
.table-body {
  @apply divide-y divide-gray-200 text-sm font-normal;
}
.table-data {
  @apply whitespace-nowrap px-4 py-4 text-gray-700;
}

.card-container {
  @apply bg-white rounded-xl border border-[#E0E2E7] shadow p-3;
}

.active-link{
    @apply text-primary;
}
.auth-input{
   @apply text-xs text-primary focus:border
           focus:outline-none focus:border-primary 
           px-3 border border-gray-200 rounded-lg w-full py-3 ;
}
.input-md{
  @apply text-xs text-gray-700 focus:border
          focus:outline-none focus:border-primary 
          px-3 border border-gray-200 rounded-lg w-full py-3 ;
}
@media screen and (max-width: 500px) {
   .m-hidden{ display: none; }
   .m-text-lg{ @apply text-lg;}
}
.m-nav-links{
  @apply mt-3;
}
.m-nav-links a{
   @apply block w-full py-4 border-t pl-3 border-gray-200 ;
}
.m-nav-links a:last-child{
  @apply block w-full py-3 border-b border-gray-200 ;
}
.\!bg-success-100 {
  background-color: #f1f0f9 !important;
}
.\!text-success-700{
  color: #fff !important;
}
.steps-wrapper div:hover{
  cursor: pointer;
}
div[data-testid="ps-sidebar-container-test-id"]{
  background-color: transparent;
}
div[data-testid="ps-sidebar-container-test-id"] li{
  @apply my-0 py-0
}
div[data-testid="ps-sidebar-container-test-id"] li, 
div[data-testid="ps-sidebar-container-test-id"] a{
  color: #fff !important;
  @apply text-sm;
}
div[data-testid="ps-sidebar-container-test-id"] a:focus,
div[data-testid="ps-sidebar-container-test-id"] a:active{
  {/*background: #FFF !important;*/}
  color: #FF9100 !important;
}
div[data-testid="ps-sidebar-container-test-id"] a:hover{
  background-color: transparent !important;
  color: #FF9100 !important;
}
div[data-testid="ps-submenu-content-test-id"]{
   background-color: transparent;
}
li.ps-submenu-root,li.ps-submenu-root:active, li.ps-submenu-root:focus, li.ps-submenu-root:hover {
  background-color: transparent !important;
}
div.ps-submenu-content a{
   height: 30px !important;
}
li.ps-active:hover{
  background-color: #fff !important;
  border-radius: 10px !important;
}

.primary-form-input{
   @apply py-3 px-3 text-xs border border-gray-200 
   focus:outline-none focus:border-primary rounded-lg;
}
.btn-styled{
    @apply py-2 rounded-lg text-xs border px-8;
}
.fjbic{
  @apply flex justify-between items-center;
}
.fjb{
  @apply flex justify-between;
}
.error-style{
  @apply text-red-500 text-xs mt-2;
}
.input-style{
  @apply rounded-sm flex-1 text-sm appearance-none bg-primaryGray border
        border-gray-300 w-full py-3 px-4 bg-transparent text-gray-700
         placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primaryBlue
          focus:border-transparent;
}


.loader {
  width: 50px;
  height: 5px;
  border: 1px solid #fff;
  position: relative;
}

.loader::before {
  content: '';
  position: absolute;
  width: 0;
  height: 100%;
  left: 0;
  animation: loading 3s infinite;
  @apply rounded-full bg-green-500;
}

@keyframes loading {
  0% {
    width: 0;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}
 .clients-table::-webkit-scrollbar{
  width: 5px;
  height: 5px;
  margin-top: 5px;
}
.view-client-link, view-client-link *{
  @apply hover:text-armyGreen
}
.tox-statusbar__branding{
  display: none !important;
}
.jswdLm .blockbuilder-branding {
    display: none;
    pointer-events: none;
    font-size: 100px;
}
.react-daterange-picker__wrapper{
  border: 1px solid #cacaca !important;
  padding: 5px 10px;
  border-radius: 5px;
  width: 300px;
}
input[type="checkbox"] {
  width: 16px;
  height: 16px;
  border: 2px solid #cccccc; /* Light gray border */
  border-radius: 3px; /* Slightly rounded corners */
  background-color: #ffffff; /* White background */
  appearance: none; /* Revert to the default styling */
}

input[type="checkbox"]:checked {
  background-color: #FF9100 !important; /* Windows-like blue background */
  border-color: #FF9100 !important; /* Blue border when checked */
}

input[type="checkbox"]:checked::after {
  content: '';
  display: block;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: relative;
  left: 4px;
  top: 1px;
}

input[type="checkbox"]:focus {
  outline: 1px dotted black; /* Focus outline */
}

input[type="checkbox"]:hover {
  border-color: #999999; /* Darker border on hover */
}

input[type="checkbox"]:disabled {
  background-color: #f0f0f0; /* Light gray background when disabled */
  border-color: #cccccc; /* Light gray border when disabled */
  cursor: not-allowed; /* Show not-allowed cursor */
}
@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/violet.css';

/* reset */
button {
  /* all: unset; */
}

.DropdownMenuContent,
.DropdownMenuSubContent {
  min-width: 150px;
  background-color: white;
  border-radius: 6px;
  padding: 5px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}
.DropdownMenuContent[data-side='top'],
.DropdownMenuSubContent[data-side='top'] {
  animation-name: slideDownAndFade;
}
.DropdownMenuContent[data-side='right'],
.DropdownMenuSubContent[data-side='right'] {
  animation-name: slideLeftAndFade;
}
.DropdownMenuContent[data-side='bottom'],
.DropdownMenuSubContent[data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.DropdownMenuContent[data-side='left'],
.DropdownMenuSubContent[data-side='left'] {
  animation-name: slideRightAndFade;
}

.DropdownMenuItem,
.DropdownMenuCheckboxItem,
.DropdownMenuRadioItem,
.DropdownMenuSubTrigger {
  font-size: 13px;
  line-height: 1;
  @apply text-gray-900;
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 5px;
  position: relative;
  padding-left: 25px;
  user-select: none;
  outline: none;
}
.DropdownMenuSubTrigger[data-state='open'] {
  @apply bg-customBlue;
  color: #fff;
}
.DropdownMenuItem[data-disabled],
.DropdownMenuCheckboxItem[data-disabled],
.DropdownMenuRadioItem[data-disabled],
.DropdownMenuSubTrigger[data-disabled] {
  color: var(--mauve-8);
  pointer-events: none;
}
.DropdownMenuItem[data-highlighted],
.DropdownMenuCheckboxItem[data-highlighted],
.DropdownMenuRadioItem[data-highlighted],
.DropdownMenuSubTrigger[data-highlighted] {
  @apply bg-customBlue;
  color: var(--violet-1);
}

.DropdownMenuLabel {
  padding-left: 25px;
  font-size: 12px;
  line-height: 25px;
  color: var(--mauve-11);
}

.DropdownMenuSeparator {
  height: 1px;
  background-color: var(--violet-6);
  margin: 5px;
}

.DropdownMenuItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.DropdownMenuArrow {
  fill: white;
}

.IconButton {
  font-family: inherit;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 0 2px 10px var(--black-a7);
  @apply text-primary;
}
.IconButton:hover {
  background-color: var(--violet-3);
}
.IconButton:focus {
  box-shadow: 0 0 0 2px black;
}

.RightSlot {
  margin-left: auto;
  padding-left: 20px;
  color: var(--mauve-11);
}
[data-highlighted] > .RightSlot {
  color: white;
}
[data-disabled] .RightSlot {
  color: var(--mauve-8);
}

input[type="range"]::-webkit-slider-thumb {
  @apply  bg-[#1F456C] rounded-full cursor-pointer;
}

input[type="range"]::-moz-range-thumb {
  @apply  bg-[#1F456C] rounded-full cursor-pointer;
}

input[type="range"]::-ms-thumb {
  @apply bg-[#1F456C] rounded-full cursor-pointer;
}

input[type="range"]::-webkit-slider-runnable-track {
  @apply  rounded-full;
}

input[type="range"]::-moz-range-track {
  @apply  rounded-full;
}

input[type="range"]::-ms-track {
  @apply  rounded-full;
  border-color: transparent;
  background: transparent;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
span[data-testid="react-awesome-stars-rating"]{
  display: flex !important;
}

.step-counter{
  @apply absolute h-[25px] flex justify-center
   items-center right-[-13px] w-[25px] rounded-full
    bg-secondary text-white text-xs font-semibold
}
.select-custom{
  @apply rounded-sm flex-1 bg-primaryGray border
   border-gray-300 w-full py-[5px]
   px-0 bg-transparent text-gray-700 placeholder-gray-400 
   text-base focus:outline-none focus:ring-2 focus:ring-primaryBlue
    focus:border-transparent;
}/*
.select-custom, .select-custom *{
  @apply bg-primaryGray
} */
.select-custom *{
   border: none !important;
   margin: 0;
   padding: 0;
   background-color: transparent !important;
}
.select-custom input{
   padding-bottom: 4px !important;
   color: #091F46 !important;
}
.dropdown__single-value{
  @apply text-gray-800 text-sm
}
div.select-custom .dropdown__option{
   @apply text-secondary bg-white text-sm hover:text-secondary hover:bg-secondary;
}
div.select-custom .dropdown__option:hover{
   color:white !important;
   background-color: #091F46 !important;
}

.select-custom .dropdown__menu{
   background-color: #fff !important;
}
.btn-primary{
  @apply bg-secondary text-white text-sm py-2 px-8 rounded;
}
.btn{
  @apply py-3 px-5 text-xs rounded-lg text-white;
}
.btn-light{
  @apply py-3 px-5 text-xs rounded-lg text-primaryBlack;
}

.resizable-container {
  position: relative;
}

.resize-element {
  position: relative;
  background: white;
}

/* Custom resize handle styles */
.resize-element::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 2px;
  height: 2px;
  cursor: se-resize;
  background: 
    linear-gradient(45deg, transparent 50%, #cbd5e1 50%),
    linear-gradient(-45deg, transparent 50%, #cbd5e1 50%);
  background-size: 20% 20%;
  background-position: left top, right top;
  background-repeat: no-repeat;
}

/* Ensure the text remains editable while resizing */
.resize-element:focus {
  outline: none;
}
.resize-handle {
  width: 2px;
  height: 2px;
  background-color: #08a155;
  border-radius: 50%;
  position: absolute;
  z-index: 1000;
}

.react-draggable:hover .resize-handle {
  display: block;
}
.active-element-border{
  border: 2px dotted #4299e1 !important;
}
